var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "google-button",
          attrs: { type: "primary" },
          on: { click: _vm.googleLogin }
        },
        [_vm._v(" " + _vm._s(_vm.$t("login.google")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }